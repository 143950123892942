import type { SeverityLevel } from '@sentry/browser'
import type { AddEntryInfo } from '@/journal'

import { JournalContext } from '../createSentryTarget'

const MAX_INFO_SIZE = 4000 // Bytes
// NOTE: this is a temporary solution to prevent log suppression
const defaultLogTags = { preventLogSuppression: true, suppressLog: false }

function detectSentryTags(entryInfo: AddEntryInfo) {
  let { suppressLog, preventLogSuppression } = defaultLogTags

  for (const tag of entryInfo.entry.tags) {
    preventLogSuppression = preventLogSuppression || tag === 'preventLogSuppression'
    suppressLog = suppressLog || tag === 'suppressLog'
  }

  return {
    suppressLog,
    preventLogSuppression,
  }
}

/**
 * This method compose a Sentry breadcrumb, suppressing the `data` field if:
 * - `suppressLog` tag is present
 * - `info` field size exceed MAX_INFO_SIZE
 *
 * Note: data suppression is ignored if the `preventLogSuppression` tag is present
 */
export function createSentryBreadcrumb(
  entryInfo: AddEntryInfo,
  severity: SeverityLevel,
  journalContext?: JournalContext,
) {
  const { suppressLog, preventLogSuppression } = detectSentryTags(entryInfo)

  const hugeData = (entryInfo.size.keys.info ?? 0) > MAX_INFO_SIZE
  const removeData = !preventLogSuppression && (suppressLog || hugeData)

  return {
    // see: https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
    // TODO: correctly map journal entries to Sentry's type and category
    // type: '',
    category: journalContext,

    level: severity,
    message: entryInfo.entry.value,
    timestamp: Math.round(entryInfo.entry.timestamp / 1000), // UNIX timestamp (SECONDS from epoch)
    data: removeData ? { message: '-- LOG SUPPRESSED --' } : entryInfo.entry.info,
  }
}
