import type { Filter, Format } from '../typings'

import { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import {
  exportRoutesColumnSelectionAtom,
  setExportRoutesColumnSelection,
  setExportRoutesPreferences,
} from '@/atoms'
import {
  exportRoutesFromCalendar,
  getExportRouteColumns,
  exportRoutesByDates,
} from '@/features/domain/route/actions'
import { selectUserConfiguration } from '@/features/domain/user'
import { useDelayedCallback, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { unproxify } from '../../../../utils'

import { useTexts } from './useTexts'
import { Column, useController } from './useController'
import { useCreateColumns } from './useCreateColumns'

export const useControllerActions = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const texts = useTexts()
  const toast = useNotification()
  const { planType } = useSelector(selectUserConfiguration)

  const {
    close,
    updateData,
    data: {
      dates,
      filter,
      format,
      singleFile,
      includePod,
      territoryIds,
      columnsState,
      includeBarcodes,
      columnSelection,
      keepEnglishColumns,
    },
  } = useController()

  const toastId = useRef<string | number>(-1)

  const [showToastAndCloseModalAfterTimer, clearToastTimer] = useDelayedCallback(() => {
    toastId.current = toast(texts.preparingExport, {
      autoClose: false,
      closeOnClick: true,
      theme: 'light',
    })
    close?.()
  }, 2000)

  const createColumns = useCreateColumns()

  const onChangeFormat = useCallback(
    (_e, value: string) => {
      if (!value) return
      updateData({ format: value as Format })
    },
    [updateData],
  )

  const onChangeFileExportType = useCallback(
    (_e, value: string) => {
      updateData({ singleFile: value === 'true' })
    },
    [updateData],
  )

  const onChangeFilter = useCallback(
    (_e, value: string) => {
      if (!value) return
      updateData({ filter: value as Filter })
    },
    [updateData],
  )

  const onChangeIncludePod = useCallback(
    (_e, checked) => updateData({ includePod: checked }),
    [updateData],
  )

  const onChangeIncludeBarcodes = useCallback(
    (_e, checked) => updateData({ includeBarcodes: checked }),
    [updateData],
  )

  const onExport = useCallback(async () => {
    try {
      if (columnSelection.length === 0) {
        updateData({ showEmptySelectionWarning: true })
        return
      }

      setIsLoading(true)

      if (columnsState.status !== 'complete') {
        throw new Error('columns status must be complete')
      }

      const columnsSet = columnsState.columns.reduce<Record<string, string>>((acc, curr) => {
        acc[curr.id] = curr.label
        return acc
      }, {})

      const requestedColumns = columnSelection.map(columnId => ({
        columnId,
        label: columnsSet[columnId],
      }))

      showToastAndCloseModalAfterTimer()

      const currentRoutes = filter === 'selected'

      const isSimulation = planType === 'simulation'

      const config = {
        format,
        includePod,
        currentRoutes,
        requestedColumns,
      }

      const exportRoutesByDatesParams = {
        dates: unproxify(dates) as string[],
        config: {
          ...config,
          currentRoutes: true,
          includePod: false,
        },
      }

      const exportRoutesFromCalendarParams = {
        territoryIds: unproxify(territoryIds) as string[],
        dates: unproxify(dates) as string[],
        config: {
          ...config,
          singleFile,
        },
      }

      const exportRequest = await dispatch(
        isSimulation
          ? exportRoutesByDates(exportRoutesByDatesParams)
          : exportRoutesFromCalendar(exportRoutesFromCalendarParams),
      )

      // DEBUG long wait
      // await new Promise(resolve => setTimeout(resolve, 5000))

      const requestMethod = isSimulation ? exportRoutesByDates : exportRoutesFromCalendar

      if (requestMethod.fulfilled.match(exportRequest)) {
        const url = unwrapResult(exportRequest)
        if (typeof url === 'string') {
          window.open(url)
        }

        if (url === null) {
          switch (currentRoutes) {
            case true:
              toast.warning(texts.noOrdersToExportOnCurrentRoutes)
              break
            case false:
              toast.warning(texts.noOrdersToExportOnDispatchedRoutes)
              break
          }
        }
      }

      setExportRoutesColumnSelection(columnSelection)

      toast.dismiss(toastId.current)
      clearToastTimer()

      setExportRoutesPreferences({
        format,
        includePod,
        singleFile,
        currentRoutes: filter === 'selected',
        includeBarcodes,
        customizeColumns: true,
        keepEnglishColumns,
      })

      close?.()
    } catch (e) {
      toast.error(texts.genericExportRoutesFromCalendarError)
    }

    setIsLoading(false)
  }, [
    showToastAndCloseModalAfterTimer,
    keepEnglishColumns,
    clearToastTimer,
    includeBarcodes,
    columnSelection,
    singleFile,
    territoryIds,
    columnsState,
    updateData,
    includePod,
    dispatch,
    filter,
    format,
    dates,
    texts,
    close,
    planType,
    toast,
  ])

  const onLegacyExport = useCallback(async () => {
    try {
      setIsLoading(true)

      showToastAndCloseModalAfterTimer()

      const currentRoutes = filter === 'selected'

      const isSimulation = planType === 'simulation'

      const config = {
        format,
        includePod,
        includeBarcodes: true,
        currentRoutes,
      }

      const exportRoutesByDatesParams = {
        dates: unproxify(dates) as string[],
        config: {
          ...config,
          currentRoutes: true,
          includePod: false,
        },
      }

      const exportRoutesFromCalendarParams = {
        territoryIds: unproxify(territoryIds) as string[],
        dates: unproxify(dates) as string[],
        config: {
          ...config,
          singleFile,
        },
      }

      const exportRequest = await dispatch(
        isSimulation
          ? exportRoutesByDates(exportRoutesByDatesParams)
          : exportRoutesFromCalendar(exportRoutesFromCalendarParams),
      )

      // DEBUG long wait
      // await new Promise(resolve => setTimeout(resolve, 5000))

      const requestMethod = isSimulation ? exportRoutesByDates : exportRoutesFromCalendar

      if (requestMethod.fulfilled.match(exportRequest)) {
        const url = unwrapResult(exportRequest)
        if (typeof url === 'string') {
          window.open(url)
        }

        if (url === null) {
          switch (currentRoutes) {
            case true:
              toast.warning(texts.noOrdersToExportOnCurrentRoutes)
              break
            case false:
              toast.warning(texts.noOrdersToExportOnDispatchedRoutes)
              break
          }
        }
      }

      toast.dismiss(toastId.current)
      clearToastTimer()

      setExportRoutesPreferences({
        format,
        includePod,
        singleFile,
        currentRoutes: filter === 'selected',
        includeBarcodes,
        customizeColumns: false,
        keepEnglishColumns: false,
      })

      close?.()
    } catch (e) {
      toast.error(texts.genericExportRoutesFromCalendarError)
    }

    setIsLoading(false)
  }, [
    showToastAndCloseModalAfterTimer,
    clearToastTimer,
    includeBarcodes,
    territoryIds,
    singleFile,
    includePod,
    planType,
    dispatch,
    filter,
    format,
    dates,
    texts,
    close,
    toast,
  ])

  const onSelectAllExtraColumns = useCallback(
    (value: boolean) => {
      updateData({
        includeBarcodes: value,
      })
    },
    [updateData],
  )

  const onChangeKeepEnglishColumns = useCallback(
    (keepEnglishColumns: boolean) => {
      updateData({ keepEnglishColumns })
    },
    [updateData],
  )

  const onColumnSelectionChange = useCallback(
    (columnSelection: string[]) => {
      updateData({ columnSelection, showEmptySelectionWarning: false })
    },
    [updateData],
  )

  const onTerritoriesSelectionChange = useCallback(
    (territoryIds: string[]) => {
      updateData({ territoryIds })
    },
    [updateData],
  )

  const onGoToColumnSelection = useCallback(async () => {
    try {
      updateData({ columnsState: { status: 'loading' } })

      const result = await dispatch(
        getExportRouteColumns({
          currentRoutes: filter === 'selected',
          territoryIds: unproxify(territoryIds),
          includePod,
          dates: [...dates],
          mode: 'multi-territory',
        }),
      )

      if (getExportRouteColumns.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      const columns = await createColumns(result.payload, keepEnglishColumns)

      const columnSelection = replacePlaceholderInsideSelection(
        exportRoutesColumnSelectionAtom.selection,
        columns,
      )

      updateData({
        wizardStep: 'configure-columns',
        columnsState: { status: 'complete', columns },
        columnSelection,
      })
    } catch (e) {
      updateData({ columnsState: { status: 'error' } })
      toast.error(texts.genericExportRoutesFromCalendarError)
    }
  }, [
    keepEnglishColumns,
    createColumns,
    territoryIds,
    updateData,
    includePod,
    dispatch,
    filter,
    texts,
    toast,
    dates,
  ])

  const onGoToConfigureFormat = useCallback(() => {
    if (territoryIds.length >= 2) {
      updateData({ wizardStep: 'configure-format', territoriesErrorText: undefined })
    } else {
      updateData({ territoriesErrorText: texts.territoriesValidationError })
    }
  }, [updateData, territoryIds, texts])

  const backToConfigureFormat = useCallback(() => {
    updateData({ wizardStep: 'configure-format' })
  }, [updateData])

  const onGoToTerritoriesSelection = useCallback(() => {
    updateData({ wizardStep: 'configure-territories' })
  }, [updateData])

  const onChangeCustomizeColumns = useCallback(
    (customizeColumns: boolean) => {
      updateData({ customizeColumns })
    },
    [updateData],
  )

  return {
    onExport,
    isLoading,
    onChangeFormat,
    onChangeFilter,
    onLegacyExport,
    onChangeIncludePod,
    backToConfigureFormat,
    onGoToConfigureFormat,
    onGoToColumnSelection,
    onChangeFileExportType,
    onSelectAllExtraColumns,
    onChangeIncludeBarcodes,
    onColumnSelectionChange,
    onChangeCustomizeColumns,
    onChangeKeepEnglishColumns,
    onGoToTerritoriesSelection,
    onTerritoriesSelectionChange,
  }
}

function replacePlaceholderInsideSelection(selection: readonly string[], columns: Column[]) {
  const groups = {
    customfields: [] as string[],
    tws: [] as string[],
    loads: [] as string[],
  }

  for (const { id } of columns) {
    if (id.startsWith('CF_')) {
      groups.customfields.push(id)
    }
    if (id.startsWith('L_')) {
      groups.loads.push(id)
    }

    if (id.startsWith('tws')) {
      groups.tws.push(id)
    }
  }

  const result = selection.reduce<string[]>((acc, id) => {
    if (id === 'loads') {
      acc.push(...groups.loads)
      return acc
    }

    if (id === 'tws') {
      acc.push(...groups.tws)
      return acc
    }

    if (id === 'customfields') {
      acc.push(...groups.customfields)
      return acc
    }

    acc.push(id)

    return acc
  }, [] as string[])

  return result
}
